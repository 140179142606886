"use client";

import React, { useState, useEffect } from "react";
import { Tag } from "antd";
import { useRouter } from "next/router";
import { handleAddCart, getTotals } from "@/lib/store/Slices/CartSlice";
import { useAppDispatch } from "@/lib/store/hooks";
import { setCategory } from "@/lib/store/Slices/FilterSlice";
import MakeOffer from "../MakeOffer/MakeOffer";
import Swal from "sweetalert2";
import "./ProductCard.css";
import "../../app/globals.css";
import * as appConstants from "../../utils/constants";
import { isNewArrival } from "@/utils/dateUtils";
import Image from "next/image";

const ProductCard = (props) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [showOffer, setShowOffer] = useState(false);
  const [loginData, setLoginData] = useState({});

  useEffect(() => {
    const storedLoginData = JSON.parse(localStorage.getItem("login")) || {};
    setLoginData(storedLoginData);
  }, []);

  const toggleShowOffer = () => {
    setShowOffer(!showOffer);
  };

  const handleSwal = () => {
    Swal.fire({
      title: "Thanks for shopping with us",
      text: "Item added to cart successfully",
      icon: "success",
      showCancelButton: true,
      cancelButtonText: "Continue Shopping",
      confirmButtonColor: "#043289",
      cancelButtonColor: "#043289",
      confirmButtonText: "Go to Checkout",
    }).then((result) => {
      if (result.isConfirmed) {
        router.push("/shopping-cart");
      }
    });
  };

  const addCart = (selectedItem) => {
    dispatch(handleAddCart(selectedItem));
    dispatch(getTotals());
    handleSwal();

    if (loginData) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "USD",
          value: selectedItem.salePrice,
          items: [
            {
              item_name: selectedItem.description,
              item_id: selectedItem.id,
              price: selectedItem.salePrice,
              item_brand: selectedItem.brandName,
              item_category: selectedItem.categoryName,
              item_list_name: "Inventory Detail Page",
              index: 0,
              quantity: 1,
            },
          ],
        },
      });
    }

    if (window.uetq) {
      window.uetq.push({
        ea: "AddToCart", // Event Action
        el: selectedItem.title, // Event Label (e.g., product name)
        ev: selectedItem.salePrice, // Event Value (e.g., product price)
      });
    }
  };

  const buyNow = (selectedItem) => {
    dispatch(handleAddCart(selectedItem));
    dispatch(getTotals());
    if (window.uetq) {
      window.uetq.push({
        ea: "BuyNow", // Event Action
        el: selectedItem.title, // Event Label (e.g., product name)
        ev: selectedItem.salePrice, // Event Value (e.g., product price or revenue)
      });
    }
    router.push("/shopping-cart");
  };

  const navigateToDetailPage = (id) => {
    const targetUrl = `${id}`;
    if (window.innerWidth > 768) {
      // 768px is a common breakpoint for mobile devices
      window.open(targetUrl, "_blank");
    } else {
      router.push(targetUrl);
    }
  };

  const navigateToInventoryPage = () => {
    const selectItem = {
      id: props.productDetail.RelatedCategoryId,
      name: props.productDetail.categoryName,
    };
    dispatch(setCategory(selectItem));
    router.push("/Inventory");
  };

  const appriasedValueExists = () => {
    return props.productDetail.salePrice && props.productDetail.salePrice > 0;
  };

  const renderImage = () => {
    const { coverImageURL, description } = props.productDetail;
    if (coverImageURL) {
      const imgSrcRegex = /src="(.*?)"/;
      const match = coverImageURL.match(imgSrcRegex);
      const imageUrl = match ? match[1] : null;

      if (imageUrl) {
        return (
          <div
            className="img-fluid"
            style={{ height: "100%", position: "relative" }}
          >
            <Image
              src={imageUrl}
              alt={description || "Product Image"}
              // layout="fill"
              height={200}
              width={200}
              loading="lazy"
              objectFit="cover"
              className="productcard-img"
            />
            {isNewArrival(props.productDetail.createDate) && (
              <div className="new-arrival-overlay">New Arrival</div>
            )}
          </div>
        );
      }
    }
    return (
      <Image
        src="/assets/images/not-found.png"
        alt="No Image"
        // layout="fill"
        height={200}
        width={200}
        objectFit="cover"
      />
    );
  };

  const renderButtons = () => (
    <div className="ProductBtnWrap">
      {appriasedValueExists() && (
        <>
          <div className="productcard-button-container">
            <a
              href="#"
              className="btn btn-blue btnFullCart"
              onClick={(e) => {
                e.preventDefault();
                addCart(props.productDetail);
              }}
            >
              Add To Cart
            </a>
            <a
              href="#"
              onClick={toggleShowOffer}
              className="btn btn-blue btnFullBuyNow"
            >
              Make an Offer
            </a>
          </div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              buyNow(props.productDetail);
            }}
            className="btn btn-blue buynowButton"
          >
            Buy Now
          </a>
        </>
      )}
    </div>
  );

  return (
    <>
      <div className="ProductCardItem DesktopProduct ">
        <div
          className="ProductImg"
          onClick={() => navigateToDetailPage(props.productDetail.productSlug)}
        >
          {renderImage()}
          <div className="product-flex">
            {/* <div>
              {props.productDetail.categoryName && (
                <a
                  className="whiteTag"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateToInventoryPage();
                  }}
                >
                  {props.productDetail.categoryName}
                </a>
              )}
            </div> */}
            {/* <div className="for-rent">
              {props.productDetail.isLocalPickupOnly && (
                <a href="#" className="rentTag">
                  Pickup Only
                </a>
              )}
            </div> */}
          </div>
        </div>

        <div className="ProductInfo">
          <a
            href={`https://kingsurplus.com${props.productDetail.productSlug}`}
            // onClick={(e) => {
            //   e.preventDefault();
            //   navigateToDetailPage(props.productDetail.productSlug);
            // }}
          >
            <h5 className="title title_link">
              {props.productDetail.description}
            </h5>
          </a>
          <h6 className="indsutryname-productcard">
            <a
              href={`https://kingsurplus.com${props.productDetail.productSlug}`}
            >
              {props.productDetail.industryName}
            </a>
          </h6>
          {props.productDetail.quantity > 0 && (
            <Tag color="green">
              Available Quantity : {props.productDetail.quantity}
            </Tag>
          )}
          {props.productDetail.isSpecializedFreightTransit === true && (
            <Tag color="blue">Specialized Freight Transit</Tag>
          )}
          {props.productDetail.isLocalPickupOnly === true && (
            <Tag color="blue">Pickup Only</Tag>
          )}
          {appriasedValueExists() ? (
            <h6 className="salePrice-productcard">
              ${appConstants.formattedValue(props.productDetail.salePrice)}
            </h6>
          ) : (
            <h5 className="my-5">{""}</h5>
          )}
          {renderButtons()}
        </div>
      </div>

      {showOffer && (
        <MakeOffer
          item={props.productDetail}
          close={toggleShowOffer}
          show={showOffer}
        />
      )}
    </>
  );
};

export default ProductCard;
