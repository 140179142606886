import React, { useState, useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import { Col, Row } from "react-bootstrap";
import ProductCard from "../ProductCard/ProductCard";
// import IndustryListView from "../IndustyListView/IndustryListView";
import * as appConstants from "../../utils/constants";
import { Icon } from "@iconify/react";
import CX from "classnames";
import axiosClient from "../../utils/axiosClient"; // Ensure axiosClient is properly set up
import "./HomeProductSection.css";

// Dynamically import the IndustryListView component
const IndustryListView = dynamic(
  () => import("../IndustyListView/IndustryListView"),
  {
    loading: () => <div className="loading-industry">Loading...</div>,
    ssr: true,
  }
);

const HomeProductSection = ({ industryData, recentProducts }) => {
  const [recentProductList, setRecentProductList] = useState(
    recentProducts || []
  );

  // console.log("`recentProductList` in HomeProductSection:", recentProductList);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(recentProducts ? true : false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const hasRunViewItem = useRef(false);

  // Client-side effect to handle dataLayer (Google Analytics or similar)
  useEffect(() => {
    if (!hasRunViewItem.current && recentProductList.length > 0) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          currency: "USD",
          items: recentProductList.map((item) => ({
            item_name: item.description,
            item_id: item.id,
            price: item.salePrice,
            affiliation: "Online Store",
            coupon: "KSC",
            discount: item.discount,
            index: 0,
            item_brand: item.brandName,
            item_category: item.categoryName,
            item_list_id: "Recent Products",
            item_list_name: "Recent Products",
            quantity: item.quantity,
            id: item.assetNumber,
          })),
        },
      });
      hasRunViewItem.current = true; // Set the ref to true after the code has run
    }
  }, [recentProductList]);

  const doToggleSidebar = () => {
    setToggleSidebar(!toggleSidebar);
  };

  return (
    <div className="HomeProductSection">
      <div className="product-wrap">
        <div
          className={CX("product-sidebar", {
            show: toggleSidebar,
          })}
        >
          <h5>Browse by Industries</h5>

          {/* Pass industryData as props to IndustryListView */}
          <IndustryListView industryData={industryData} />
        </div>
        <div className="product-main-list">
          <div className="product-header-wrap">
            <h2>Our Inventory</h2>
            <button
              className="CloseSidebar"
              aria-label="Close"
              onClick={doToggleSidebar}
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <Icon icon="ic:sharp-close" />
            </button>
          </div>
          {isLoaded ? (
            <>
              {error ? (
                <p>Error loading products: {error.message}</p>
              ) : (
                <Row className="homePageRow">
                  {recentProductList.map((item) => {
                    const seenProductIds = new Set();
                    const isUnique = !seenProductIds.has(item.id);
                    if (isUnique) {
                      seenProductIds.add(item.id);
                    }
                    return (
                      isUnique && (
                        <Col key={item.id} xl={4} lg={4} md={6}>
                          <ProductCard key={item.id} productDetail={item} />
                        </Col>
                      )
                    );
                  })}
                </Row>
              )}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      <div className="button-container">
        <a href="/Inventory" className="viewMoreButton">
          View more <Icon icon="ic:sharp-keyboard-arrow-right" />
        </a>
      </div>
    </div>
  );
};

export default HomeProductSection;
